import React from 'react';
import { CoverPage } from '../../components/magazine/CoverPage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Magazine } from '@yellow-owl/client-sdk';

export const CoverPageContainer: React.FC = () => {
  const [searchParams] = useSearchParams();

  const magazine: Magazine = {
    coverPicUrl: searchParams.get('coverPicUrl')!,
    createdAt: '',
    thumbnailUrl: '',
    title: '',
    updatedAt: '',
    id: Number(searchParams.get('id')!),
  };

  const navigate = useNavigate();
  const onCoverClick = (magazineId: number) => {
    navigate({
      pathname: '/magazine/index',
      search: `?id=${magazineId}`,
    });
  };

  return <CoverPage name={name!} magazine={magazine} onCoverClick={onCoverClick} />;
};
