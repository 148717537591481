import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton, Stack, SxProps, Theme, Tooltip, styled } from '@mui/material';
import ReactS3Client from 'react-aws-s3-typescript';
import { ActivitySkillType, InputContent, QuestionFormat } from '@yellow-owl/client-sdk';
import { YellowOwlContext } from '../../../context/YellowOwlContext';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../confirmation-modal/confirmation-modal';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const imageUploaderBoxStyle: SxProps<Theme> = {
  margin: '4vh auto',
  cursor: 'pointer',
};

const fileUploaderStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '30vw',
  height: '37vh',
  color: 'rgba(0, 0, 0, 0.6)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
};

const fileUploadBtnBox: SxProps<Theme> = {
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
};

const deleteImageBtnStyle: SxProps<Theme> = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '10px',
  right: '10px',
  background: '#0000005c',
  height: '40px',
  width: '40px',
  borderRadius: '4px',
  color: 'white',
  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
};

const cameraViewBox: SxProps<Theme> = {
  position: 'relative',
  width: '30vw',
  // height: '37vh',
  background: 'gray',
  overflow: 'hidden',
  img: {
    width: '100%',
    height: '100%',
  },
};

const imageCaptureBtns: SxProps<Theme> = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  bottom: '15px',
  left: '50%',
  transform: 'translate(-50%, 0)',
  gap: '10px',
  button: {
    height: '50px',
    width: '50px',
    margin: '0px 10px',
    background: '#fbec53',
    '&:hover': {
      background: '#ffe800',
    },
  },
};

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET!,
  region: process.env.REACT_APP_S3_REGION!,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY!,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
};

const mimeType = 'video/webm; codecs="opus,vp8"';

function ThinkOutsideImageUploader(props: ThinkOutsideImageUploaderProps) {
  const s3 = new ReactS3Client(config);
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const {
    questionFormat,
    userId,
    magazineId,
    questionId,
    inputContent,
    questionFormatId,
    tenantId,
    categoryId,
    activityId,
    skill,
    url,
    globalInputState,
    onUpdateInputContents,
  } = props;

  const [file, setFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState<string>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [showUploadImageBtn, setShowUploadImageBtn] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  const saveImageResponse = async (url: string) => {
    try {
      // let isMandatory = false;
      inputContent.forEach((inputElt: InputContent) => {
        inputElt.questionData.forEach((questionData) => {
          if (questionData.id === questionId) {
            questionData.questionFormat.forEach((questionFormat) => {
              if (questionFormat.format.id === questionFormatId) {
                // isMandatory = !!questionFormat?.format?.mandatory;
                questionFormat.format.response = url;
              }
            });
          }
        });
        if (questionFormat.format.mandatory) {
          setIsValid(url.trim().length > 0);
        } else {
          setIsValid(true);
        }
      });
      const result = await yellowOwlApi.addUserResponse({
        userId: +userId,
        magazineId: +magazineId,
        activityId: activityId,
        inputContent: inputContent,
        tenantId: tenantId,
        categoryId: categoryId,
        skill: skill,
      });
      if (result) {
        /* notification */
        onUpdateInputContents(inputContent);
      }
    } catch (e) {
      /**
       * Need to show notification accordingly
       */
    }
  };

  const uploadImagToS3 = async (file: File) => {
    if (file) {
      try {
        s3.uploadFile(file).then((data: any) => {
          setFileUrl(data.location);
          saveImageResponse(data.location);
          setShowUploadImageBtn(false);
        });
      } catch (exception) {
        /* handle the exception */
      }
    }
  };

  // TODO: this is not the right way to upload the file, we should be using signed token from the blob storage to do the same
  useEffect(() => {
    if (file) {
      uploadImagToS3(file);
    }
  }, [file]);

  useEffect(() => {
    const validateAndSetAudio = async () => {
      if (url) {
        setFileUrl(url);
        setShowUploadImageBtn(false);
      } else {
        setShowUploadImageBtn(true);
      }
    };
    validateAndSetAudio();
  }, []);

  useEffect(() => {
    if (fileUrl && globalInputState) {
      if (questionFormat.format.mandatory) {
        if (questionFormat.format.response && checkResponse(questionFormat.format.response)) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(true);
      }
    }
  }, [fileUrl, globalInputState]);

  const handleDelete = () => {
    setOpenConfirmationModal(true);
  };

  const handleConfirmationModalClose = async (userResponse: boolean) => {
    setOpenConfirmationModal(false);
    if (userResponse) {
      try {
        const filePath = config.bucketName + '/' + fileUrl?.split('.com/')[1];
        // console.log(filePath);
        await s3.deleteFile(filePath!);
        setFileUrl('');
        setShowUploadImageBtn(true);
        await saveImageResponse('');
      } catch (e) {
        /**
         * Need to show notification accordingly
         */
      }
    }
  };

  const startRecording = async () => {
    setShowUploadImageBtn(false);
    setOpenCamera(true);
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    streamRef.current = stream;
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
    const recorder = new MediaRecorder(stream, { mimeType });
    recorder.start();
    setMediaRecorder(recorder);
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  const captureImage = async () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve, 'image/png'));
        if (blob) {
          stopRecording();
          const file = new File([blob], 'captured-image.png', { type: 'image/png' });
          uploadImagToS3(file);
          setOpenCamera(false);
        } else {
          console.error('Error capturing image: Blob is null');
        }
      }
    }
  };

  const handleCancelcaptureImage = () => {
    stopRecording();
    setShowUploadImageBtn(true);
    setOpenCamera(false);
  };

  function isStringArray(value: any): value is string[] {
    return Array.isArray(value);
  }

  function checkResponse(response: string | string[]): boolean {
    if (typeof response === 'string') {
      return response.trim() !== '';
    } else if (isStringArray(response)) {
      return response.every((res) => res.trim() !== '');
    }
    return false;
  }

  useEffect(() => {
    if (globalInputState) {
      if (questionFormat.format.mandatory) {
        if (questionFormat.format.response && checkResponse(questionFormat.format.response)) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(true);
      }
    }
  }, [globalInputState]);

  const fileUploadBackground = {
    width: '30vw',
    height: '37vh',
    background: `url(${fileUrl})`,
    backgroundSize: '100% 100%',
    border: isValid ? '4px solid #673AB7' : '4px solid red',
    borderRadius: '5px',
    position: 'relative',
  };

  return (
    <Box sx={imageUploaderBoxStyle}>
      <Stack direction={'row'} justifyContent={'space-around'}>
        <Box sx={fileUploadBackground}>
          <Box sx={cameraViewBox} height={openCamera ? '37vh' : '0vh'}>
            <Box sx={{ height: '37vh', width: '30vw' }}>
              <video ref={videoRef} autoPlay muted width={'100%'} height={'100%'}></video>
            </Box>
            <Box sx={imageCaptureBtns}>
              <IconButton onClick={captureImage}>
                <CameraEnhanceIcon sx={{ color: '#000' }} />
              </IconButton>
              <IconButton onClick={handleCancelcaptureImage}>
                <CancelIcon sx={{ color: '#000' }} />
              </IconButton>
            </Box>
          </Box>

          {showUploadImageBtn && (
            <Box sx={fileUploaderStyle}>
              <Box sx={fileUploadBtnBox}>
                <Button
                  component='label'
                  role={undefined}
                  variant='contained'
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  fullWidth
                  sx={{ margin: '10px' }}
                >
                  Upload Photo
                  <VisuallyHiddenInput type='file' accept='image/*' onChange={handleFileChange} />
                </Button>
                <Button
                  component='label'
                  variant='contained'
                  tabIndex={-1}
                  onClick={startRecording}
                  startIcon={<CameraAltIcon />}
                  fullWidth
                  sx={{ margin: '10px' }}
                >
                  Take Photo
                </Button>
              </Box>
            </Box>
          )}
          {fileUrl && (
            <Box sx={deleteImageBtnStyle}>
              <Tooltip title={'Delete'} placement='top'>
                <IconButton onClick={handleDelete}>
                  <DeleteIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Stack>
      <ConfirmationModal
        title='Delete Image'
        content='Are you sure you want to delete this image?'
        open={openConfirmationModal}
        onClose={handleConfirmationModalClose}
      />
    </Box>
  );
}

interface ThinkOutsideImageUploaderProps {
  questionFormat: QuestionFormat;
  image: string;
  inputContent: Array<InputContent>;
  questionId: number;
  questionFormatId: number;
  userId: string;
  magazineId: string;
  tenantId: number;
  categoryId: number;
  activityId: string;
  skill: Array<ActivitySkillType>;
  url: string;
  globalInputState: boolean;
  onUpdateInputContents: (input: Array<InputContent>) => boolean;
}

export default ThinkOutsideImageUploader;
